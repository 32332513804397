@import "../../styles/scss/mixins/breakpoints";

.today {
  @include breakpoint-from("lg") {
    padding: 20px 0;
  }

  &__body {
    @include breakpoint-from("sm") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }

  &__title {
    text-align: center;
    font-size: 30px;
    color: #fff;

    @include breakpoint-from("sm") {
      margin: 0;
      text-align: right;
      font-size: 22px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  .actions {
  }

  .button {
    @include breakpoint-to("sm") {
      display: block;
      width: 100%;
    }
  }
}
