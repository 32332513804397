@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.hero {
  overflow: hidden;

  @include breakpoint-from("lg") {
    padding-top: 50px;
  }

  &__top {
    @include breakpoint-from("md") {
      display: flex;
    }
  }

  &__info {
    flex: 1;
    min-width: 50%;

    @include breakpoint-from("lg") {
      margin-right: 80px;
    }
  }

  &__title {
    font-size: 33px;

    @include breakpoint-from("lg") {
      font-size: 40px;
    }

    @include breakpoint-from("xl") {
      font-size: 52px;
    }

    &__highlight {
      color: $secondary-color;
    }
  }

  .section__description {
    text-align: left;
  }

  &__actions {
    margin-bottom: 40px;

    .button {
      padding-left: 24px;
      padding-right: 24px;

      & + .button {
        margin-left: 10px;
      }
    }
  }

  &__dashboard {
    position: relative;

    @include breakpoint-from("md") {
      width: 50%;
      padding-bottom: 20px;
    }

    @include breakpoint-from("lg") {
      margin-top: -20px;
      min-height: 596px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 5px;
      width: 289px;
      height: 289px;
      border-radius: 50%;
      background: transparent url(./bg.png) repeat center center;

      @include breakpoint-from("lg") {
        width: 596px;
        height: 596px;
      }
    }

    &__img {
      overflow: hidden;
      position: relative;
      padding: 20px;
      margin-left: -15px;
      margin-right: -15px;

      @include breakpoint-from("lg") {
        padding-top: 50px;
        overflow: visible;
      }

      img {
        display: block;
        height: 250px;
        max-width: none;
        filter: drop-shadow(0px 0px 10px #dadfe8);
        border-radius: 12px;

        @include media-from(491px) {
          height: auto;
          width: 100%;
        }

        @include breakpoint-from("md") {
          height: 250px;
          width: auto;
        }

        @include breakpoint-from("lg") {
          height: 490px;
        }
      }
    }
  }

  &__counters {
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;

    @include breakpoint-from("sm") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint-from("md") {
      grid-template-columns: repeat(5, 1fr);
    }

    &__counter {
      text-align: center;
      color: $primary-color;

      & + & {
        @include breakpoint-from("md") {
          border-left: solid 2px #edf3ff;
        }
      }
    }

    &__value {
      font-size: 32px;
      font-weight: 700;

      @include breakpoint-from("lg") {
        font-size: 24px;
      }
    }

    &__label {
      font-size: 13px;
      color: $itb-black;
    }
  }
}
