@import '../../styles/scss/variables';
@import '../../styles/scss/mixins/button';
@import '../../styles/scss/mixins/breakpoints';

.button {
  $root: &;

  border: solid 2px;
  display: inline-block;
  padding: 1em;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  transition: background-color ease-in-out .25s, border-color ease-in-out .25s, color ease-in-out .25s;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  max-width: 100%;
  @include button-variation(transparent, $tertiary-color, $text-color, #F2F5FF, #23366E, #23366E);

  // @include breakpoint-from('xl') {
  //   padding-left: 1.2em;
  //   padding-right: 1.2em;
  // }

  &__text {
    color: inherit;

    // #{$root}--loading & {
    //   opacity: 0;
    // }
  }

  // &__spinner {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  &--block {
    display: block;
    width: 100%;
  }

  // &--primary {
  //   &:not(.button--outline) {
  //     @include button-variation($primary-color, $primary-color, #fff, $secondary-color, $secondary-color, #fff);
  //   }

  //   &.button--outline {
  //     @include button-variation(transparent, $primary-color, $primary-color, $secondary-color, $secondary-color, #fff);
  //   }
  // }

  &--secondary {
    &:not(.button--outline) {
      @include button-variation($secondary-color, $secondary-color, #fff, $primary-color, $primary-color, #fff);
    }

    &.button--outline {
      @include button-variation(transparent, $secondary-color, $secondary-color, $primary-color, $primary-color, #fff);
    }
  }

  &--light {
    &:not(.button--outline) {
      @include button-variation($light-color, $light-color, $secondary-color, $primary-color, $primary-color, #fff);
    }

  //   &.button--outline {
  //     @include button-variation(transparent, $light-color, $light-color, #F5F8FF, #fff, #23366E);
  //   }
  }

  // &--error {
  //   @include button-variation(#721c24, #721c24, #fff);
  // }
}
