@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?q7ad88');
  src:  url('fonts/icomoon.eot?q7ad88#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?q7ad88') format('truetype'),
    url('fonts/icomoon.woff?q7ad88') format('woff'),
    url('fonts/icomoon.svg?q7ad88#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-medium:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e900";
}
.icon-youtube:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
