@import '../../styles/scss/mixins/breakpoints';

.header {
  background-color: #fff;
  margin: -20px 0 20px;

  @include breakpoint-from('xl') {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  &__logo {
    display: block;
    height: 40px;

    @include breakpoint-from('lg') {
      height: 50px;
    }
  }
}