@import '../variables';

@mixin media-from($px) {
  @media (min-width: $px) {
    @content;
  }
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin breakpoint-from($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @include media-from(map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin breakpoint-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint) - 1}) {
      @content;
    }
  }
}
