@import '../../styles/scss/mixins/breakpoints';

.footer {
  background-color: #ffffff;

  &__main-container {
    max-width: 1200px;
    margin: auto;

    @include breakpoint-from('sm') {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 20px 0;
    }

    @include breakpoint-from('lg') {
      padding: 25px 0 15px;
    }
  }

  &__group {
    $root: &;

    &--collapsible {
      @include breakpoint-to('sm') {
        border-bottom: #f6f6f6 solid 1px;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      color: #43484d;
      padding: 15px;

      @include breakpoint-from('sm') {
        padding-left: 20px;
      }

      @include breakpoint-from('lg') {
        margin-bottom: 5px;
      }

      #{$root}--collapsible & {
        @include breakpoint-to('sm') {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-left: 5px solid #ffffff;
        }
      }

      #{$root}--collapsible.is-active & {
        @include breakpoint-to('sm') {
          border-color: $primary-color;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, .05);
          background-color: #fafafa;
        }
      }
    }

    &__caret {
      display: none;

      @include breakpoint-to('sm') {
        display: block;
        fill: $primary-color;
        margin-left: auto;
        transition: transform ease-in-out .5s;
      }

      #{$root}.is-active & {
        transform: rotate(540deg);
      }
    }

    &__content {
      padding: 10px 20px 20px;

      @include breakpoint-from('sm') {
        padding-top: 0;
      }

      #{$root}--collapsible & {
        @include breakpoint-to('sm') {
          display: none;
        }
      }

      #{$root}--collapsible.is-active & {
        @include breakpoint-to('sm') {
          display: block;
        }
      }

      a {
        display: block;
        color: #8c8c8c;
        font-size: 12px;
        padding: 8px 0;

        @include breakpoint-from('lg') {
          font-size: 13px;
        }

        // @include breakpoint-from('sm') {
        //   padding: 5px 0;
        // }
      }
    }

    &--collapsible {
      @include breakpoint-from('sm') {
        width: 33%;
      }

      @include breakpoint-from('lg') {
        width: 25%;
      }
    }

    &--contact {
      #{$root} {
        width: 100%;
      }

      @include breakpoint-from('sm') {
        width: 100%;
        border-top: #F8F9FC solid 1px;
      }

      @include breakpoint-from('lg') {
        border-top: 0;
        width: 25%;
      }

      #{$root}__title {
        @include breakpoint-from('sm') {
          text-align: center;
        }

        @include breakpoint-from('lg') {
          text-align: left;
        }
      }

      #{$root}__content {
        @include breakpoint-from('sm') {
          text-align: center;
        }

        @include breakpoint-from('lg') {
          text-align: left;
        }
      }
    }

    &--social {
      @include breakpoint-to('lg') {
        padding-top: 20px;
      }

      @include breakpoint-from('sm') {
        width: 100%;
        border-top: #F8F9FC solid 1px;
      }

      @include breakpoint-from('lg') {
        width: 25%;
        border: 0;
      }

      #{$root}__title {
        @include breakpoint-to('lg') {
          text-align: center;
          padding-bottom: 0;
        }

        @include breakpoint-from('lg') {
          text-align: left;
        }
      }

      #{$root}__content {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;

        @include breakpoint-from('lg') {
          justify-content: start;
          margin-left: -10px;
        }
      }

      a {
        font-size: 24px;
        padding: 10px;
        color: $secondary-color;

        @include breakpoint-from('lg') {
          font-size: 18px;
        }
      }
    }
  }

  &__copy {
    color: #8c8c8c;
    background-color: #EDF3FF;
    padding: 5px 0;

    @include breakpoint-from('lg') {
      padding: 10px 0;
    }
  }

  &__copy-container {
    padding: 0 15px;
    max-width: 1200px;
    margin: auto;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;

      @include breakpoint-from('lg') {
        justify-content: start;
      }
    }
  }

  &__copy-logo {
    margin-right: 10px;
  }
}