.text-area {
  background: transparent;
  border: 1px solid #596C95;
  border-radius: 6px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px;
  vertical-align: bottom;

  &::placeholder {
    color: rgba(255, 255, 255, .6);
  }

  &[disabled] {
    opacity: .5;
  }

  &--block {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
