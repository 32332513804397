@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.trusted {
  &__articles {
    @include breakpoint-from("sm") {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__article {
    padding: 20px 20px 28px;

    @include breakpoint-from("sm") {
      width: 50%;
    }

    @include breakpoint-from("lg") {
      width: 33%;
      padding: 20px 50px 28px;
    }

    &__logo {
      margin-bottom: 24px;

      img {
        display: block;
        width: 48px;
        margin: auto;
      }
    }

    &__title {
      text-align: center;
      font-size: 36px;
      margin-top: 0;

      @include breakpoint-from("lg") {
        font-size: 28px;
      }

      h4 {
        font-size: inherit;
      }
    }
    &__description {
      font-size: 18px;
      text-align: center;
      color: $itb-black;

      @include breakpoint-from("lg") {
        font-size: 16px;
      }
    }
  }
}
