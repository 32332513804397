@import './variables';
@import './mixins/breakpoints';

:root {
  --spinner-color: #{$text-color};
}

body {
  color: $text-color;
  font-family: 'Graphik';
  background: #{$body-bg};
  font-size: 16px;
  line-height: 1.3;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: $title-color;
  margin-top: 0;
  margin-bottom: .5em;
  line-height: 1.2;
}

h1, h2 {
  font-size: 36px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

p {
  margin-bottom: .5em;
  line-height: 1.5;
}

strong {
  font-weight: 600;
}

.container {
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
}

.disclaimer {
  h5, p {
    color: #374151;
    font-size: 12px;
    line-height: 20px;
  }
}