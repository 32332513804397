@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.section {
  padding: 40px 0 24px;

  @include breakpoint-from("lg") {
    padding: 56px 0;
  }

  @include breakpoint-from("xl") {
    padding: 88px 0 72px;
  }

  &__top-title {
    text-align: center;
    color: #{$secondary-color};
    font-size: 18px;
    margin-bottom: 10px;

    &--left {
      text-align: left;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 16px;
    font-size: 42px;

    &--left {
      text-align: left;
      font-size: 32px;
      font-weight: 400;
    }

    .section--primary &,
    .section--secondary & {
      color: #fff;
    }
  }

  &__description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 1.8;
    max-width: 700px;
    margin: auto auto 20px auto;

    @include breakpoint-from("lg") {
      margin-bottom: 35px;
      font-size: 18px;
    }

    .section--primary &,
    .section--secondary & {
      color: #fff;
    }

    &--small {
      @include breakpoint-from("lg") {
        font-size: 16px;
      }
    }

    &--left {
      text-align: left;
    }
  }

  &__actions {
    text-align: center;
    margin-bottom: 20px;

    @include breakpoint-from("lg") {
      margin-bottom: 35px;
    }

    &--left {
      text-align: left;
    }

    .button {
      @include breakpoint-to("sm") {
        display: block;
        width: 100%;
      }

      @include breakpoint-from("sm") {
        padding-left: 50px;
        padding-right: 50px;
      }

      & + .button {
        @include breakpoint-to("sm") {
          margin-top: 10px;
        }

        @include breakpoint-from("sm") {
          margin-left: 10px;
        }
      }
    }
  }

  &--light {
    background-color: #fff;
  }

  &--secondary {
    background-color: $secondary-color;
  }

  &--primary {
    background-color: $primary-color;
  }

  ol {
    padding-left: 30px;

    li {
      margin-bottom: 0.5em;
    }
  }

  ul {
    li {
      margin-bottom: 0.5em;
    }
  }

  .indented {
    padding-left: 24px;
  }

  .underlined {
    text-decoration: underline;
  }
}
