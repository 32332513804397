@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.risk {
  position: relative;

  &__side_image {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 50%;
    max-height: 440px;
    @include breakpoint-from("lg") {
      display: block;
    }
  }

  &__articles {
    width: 80%;
    @include breakpoint-from("lg") {
      width: 50%;
    }
    // @include breakpoint-from("oversized") {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;

    //   & * {
    //     text-align: center;
    //   }
    // }
  }

  &__article {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    & > div:nth-child(2) {
      margin: 0;
    }

    &__logo {
      margin-right: 20px;
      & img {
        width: 48px;
        max-width: none;
      }
    }

    &__title {
      text-align: left;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      font-feature-settings: "liga" off;

      h4 {
        font-size: inherit;
        margin: 0;
      }
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: "liga" off;
      text-align: left;
      color: $itb-black;

      & ul {
        list-style-type: none;
      }

      @include breakpoint-from("lg") {
        font-size: 16px;
      }
    }
  }
}
