$breakpoints: (
  "sm": 480px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1350px,
  "oversized": 1440px,
) !default;

$itb-black: #333335;
$primary-color: #223358;
$secondary-color: #2e71f0;
$error-color: #721c24;
$light-color: #fff;
$tertiary-color: #605f62;
$title-color: #444444;
$text-color: #555458;
$body-bg: #edf3ff;
$alternative-bg: #f8f9fc;
