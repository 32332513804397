@mixin button-variation($bg-color, $border-color, $color, $hover-bg-color: darken($bg-color, 5%), $hover-border-color: darken($border-color, 5%), $hover-color: darken($color, 5%)) {
  border-color: #{$border-color};
  background-color: #{$bg-color};
  color: #{$color};
  --spinner-color: #{$color};

  @media (hover: hover) {
    &:hover {
      --spinner-color: #{$hover-color};
      background-color: $hover-bg-color;
      border-color: $hover-border-color;
      color: $hover-color;
    }
  }

  &:active {
    --spinner-color: darken($hover-color, 5%);
    background-color: darken($hover-bg-color, 5%);
    border-color: darken($hover-border-color, 5%);
    color: darken($hover-color, 5%);
  }
}