@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.today-form {
  overflow: hidden;

  @include breakpoint-from("md") {
    overflow: hidden;
  }

  .section__title {
    @include breakpoint-from("md") {
      text-align: left;
      font-size: 42px;
    }
  }
  .section__description {
    @include breakpoint-from("md") {
      text-align: left;
    }
  }

  &__body {
    @include breakpoint-from("md") {
      display: flex;
    }
  }

  &__mac {
    margin-top: -80px;
    margin-bottom: 24px;
    position: relative;

    @include breakpoint-from("md") {
      margin: 0;
      width: 57%;
      margin-left: -15px;
    }

    img {
      @include breakpoint-from("md") {
        max-width: none;
        position: absolute;
        right: 18px;
        top: 0;
        max-height: 100%;
      }
    }
  }

  &__form {
    @include breakpoint-from("md") {
      width: 43%;
      padding-left: 18px;
    }
    @include breakpoint-from("lg") {
      width: 43%;
      padding-left: 18px;
      padding: 36px 0 78px 18px;
    }
  }

  .section__title {
    font-size: 30px;
  }

  .button {
    @include breakpoint-to("md") {
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: $light-color;
      color: $secondary-color;
      border-color: $light-color;
    }
  }

  .contact-form {
    input,
    textarea {
      background: transparent;
      border: 1px solid #596c95;
      border-radius: 6px;
      font-size: 14px;
      color: $light-color;
      padding: 10px 20px !important;
      vertical-align: bottom;
      margin-bottom: 10px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }

      &[disabled] {
        opacity: 0.5;
      }

      &--block {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    input[type="submit"] {
      border-color: $light-color;
      background-color: $light-color;
      color: $secondary-color;
      --spinner-color: $secondary-color;
      width: 100%;
      padding: 16px !important;
      font-size: 16px;
      font-weight: 600;
    }

    .no-list {
      display: none !important;
    }

    .submitted-message p {
      color: $light-color;
    }
  }
}
