.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  &__backdrop {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
  }

  &__content {
    position: relative;
    z-index: 2;
    background-color: #fff;
    width: 600px;
    max-width: calc(100% - 40px);
    box-shadow: 0px 2px 8px rgba(35, 54, 110, 0.2);
    border-radius: 8px;
    padding: 30px;
  }
}