@import '../../styles/scss/mixins/breakpoints';

.check-modal {
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;    
    color: #152935;
    padding-top: 14px;
  }

  &__description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #152935;
    margin-bottom: 28px;
  }

  &__options {
    margin-bottom: 28px;

    @include breakpoint-from('sm') {
      display: flex;
    }
  }

  &__option {
    display: block;
    flex: 1;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 1px rgba(35, 54, 110, 0.2);
    margin-bottom: 10px;

    @include breakpoint-from('sm') {
      height: 190px;
    }

    &:first-child {
      @include breakpoint-from('sm') {
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: #EFF4FF;
      border-color: #2E71F0;
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      img {
        display: block;
        width: 75px;
      }
    }

    &__title {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #43484D;
      margin-bottom: 5px;
    }

    &__desc {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: #43484D;
    }
  }

  &__button {
    margin-bottom: 40px;
  }

  &__footer {
    text-align: center;
    font-size: 12px;
    color: #233642;

    a {
      color: #2E71F0
    }
  }
}