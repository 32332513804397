@import "../../styles/scss/mixins/breakpoints";

.de-fi {
  &__articles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    @include breakpoint-from("sm") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-dots {
    bottom: -40px;
  }

  &__article {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(234, 240, 252, 0.9);
    border-radius: 8px;
    padding: 40px 32px;
    margin-top: 0;

    @include breakpoint-from("md") {
      margin-right: 20px;
      height: 500px;
    }

    @include breakpoint-from("lg") {
      height: 370px;
    }

    @include breakpoint-from("xl") {
      height: 330px;
    }

    &__logo {
      img {
        display: block;
        width: 32px;
      }
    }
    &__title {
      font-size: 24px;
      h4 {
        font-size: inherit;
      }
    }
    &__description {
      font-size: 16px;

      p {
        font-size: inherit;
      }
    }
  }
}
