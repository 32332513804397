@import "../../styles/scss/variables";
@import "../../styles/scss/mixins/breakpoints";

.defi_coverage {
  position: relative;

  &__side_image {
    display: none;
    position: absolute;
    bottom: 5%;
    left: 0;
    max-width: 50%;
    max-height: 550px;
    @include breakpoint-from("lg") {
      display: block;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    letter-spacing: -0.025em;
    font-feature-settings: "liga" off;
  }
  &__articles {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-left: 0;

    & > article {
      margin-top: 0;
    }
    & > article:last-child {
      margin-bottom: 0;
    }

    @include breakpoint-from("lg") {
      width: 50%;
    }
  }

  &__article {
    display: flex;
    flex-direction: row;
    margin: 30px 0 30px 0;

    & > div:nth-child(2) {
      margin: 0;
    }

    &__logo {
      margin-right: 20px;
      & img {
        width: 48px;
        max-width: none;
      }
    }

    &__title {
      text-align: left;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      font-feature-settings: "liga" off;

      h4 {
        font-size: inherit;
        margin: 0;
      }
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: "liga" off;
      text-align: left;
      color: $itb-black;

      & ul {
        list-style-type: none;

        & li {
          margin-bottom: 5px;
        }
      }

      @include breakpoint-from("lg") {
        font-size: 16px;
      }
    }
  }
}
